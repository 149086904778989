<template>
  <v-card  class="elevation-0 ml-1 mr-0 pt-1 mb-2" outlined>
    <v-card-text>
      <v-data-table
          height="400px"
          :single-select="!multiSelect"
          :show-select="multiSelect"
          :headers="headers"
          :items="ftSalesdItemsFiltered"
          hide-default-footer
          disable-pagination
          class="elevation-0 mx-3 hidden-xs-only"
      >
        <template v-slot:top>
          <v-row align="center" class="ml-4 mr-4"
             v-if="formDetailMode !== 'NEW_DETIL' "
          >

            <v-col cols="12" md="6" sm="6">
              <v-autocomplete
                  v-model="itemModified.fwarehouseBean"
                  :items="itemsFWarehouse"
                  item-value="id"
                  item-text="description"
                  :rules="rulesNotEmtpy"
                  auto-select-first
                  dense
                  chips
                  deletable-chips
                  color="blue-grey lighten-2"
                  label="Gudang"
                  persistent-hint
                  :disabled="formDetailMode==='NEW_DETIL' || formDetailMode==='EDIT_DETIL' || itemModified.deliveryNumber != '' "
                  @change="reloadExpeditionDestinationFtSalesdItemsDetail"
              >
                <template v-slot:selection="data">
                  <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="itemModified.fwarehouseBean=undefined"
                  >
                    <v-avatar left>
                      <v-img :src="lookupImageUrlLazy(data.item)" class="v-size--x-small"></v-img>
                    </v-avatar>
                    {{ data.item.description }}
                    <!--              <span class="font-weight-light caption"> *{{ data.item.kode1 }} </span>-->
                  </v-chip>
                </template>

                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <v-img :src="lookupImageUrlLazy(data.item)"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.description}}</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-light">
                        {{data.item.city1}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

            </v-col>

            <v-spacer></v-spacer>

            <v-btn
                fab
                dark
                color="green"
                x-small
                @click="showDialogNew"
                :disabled="formDetailMode==='NEW_DETIL' || formDetailMode==='EDIT_DETIL' ||
                itemModified.fwarehouseBean===0 || itemModified.deliveryNumber !== '' ||
                itemModified.fsalesmanBean ===0 || itemModified.fstoreBean ===0 || itemModified.fcustomerBean===0 "

            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </template>

        <template v-slot:[`item.number`]="{ index }">
          {{ (index + ((currentPage-1)*pageSize) +1 )}}
        </template>

        <template v-slot:[`item.avatar`]="{ item}">
          <v-img
              :lazy-src="lookupImageMaterialUrl_VeryLow(item)"
              :src="lookupImageMaterialUrl_Low(item)"
              alt="avatar"
              width="60px"
              height="60px"
              class="ma-1 rounded"
          >
          </v-img>
        </template>

        <template v-slot:[`item.fmaterialBean`]="{ item }">
          <div class="fill-height mt-1">
            <div>
              <span class="text-caption">{{ lookupFMaterialPname(item.fmaterialBean) }}</span>
              <span class="text-caption blue--text font-weight-bold ml-1">*{{ lookupFMaterialPcode(item.fmaterialBean) }}</span>
            </div>
            <div>
              <span class="caption font-weight-light">IDR. </span>
              <span class="text-caption">{{ formattedCurrencyValue('', item.sprice2) }}</span>
            </div>
          </div>
        </template>

        <template v-slot:[`item.qty`]="{ item }">
          <div class="fill-height mt-1">
            <div>
              <span class="font-weight-bold">{{item.qty-item.qtyKembali}}</span>
              <span class="text-caption ml-1">{{ lookupUom4(item) }}</span>
            </div>
            <div class="mt-1 font-weight-light pink--text" v-if="item.disc1>0 || item.disc2>0 || item.disc2>0 ">
              {{item.disc1}}%
            </div>

            <div class="text-caption grey--text" v-if="item.taxPercent>0">
<!--                {{ lookupFTax(item.ftaxBean) }}-->
                {{ formattedCurrencyValue('', item.taxPercent) }}
            </div>

          </div>
        </template>


        <template v-slot:[`item.actions`]="{ item }">

          <div class="fill-height">
            <div class="font-weight-regular mt-2">
              <span class="font-weight-light">IDR</span> {{ formattedCurrencyValue('', lookupTotalAfterDiscAfterPpn(item)) }}
            </div>

            <div class="mt-0">
              <v-btn
                  @click="showDialogEdit(item)"
                  icon
                  :disabled="formDetailMode==='NEW_DETIL' || formDetailMode==='EDIT_DETIL' ||
                    itemModified.fwarehouseBean===0  || itemModified.deliveryNumber !== '' "
              >
                <v-icon
                    small
                    color="warning"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>

              <v-btn
                  @click="deleteDialogShow(item)"
                  icon
                  :disabled="formDetailMode==='NEW_DETIL' || formDetailMode==='EDIT_DETIL' || itemModified.deliveryNumber !== '' "
              >
                <v-icon
                    small
                    color="red accent-4"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </div>

        </template>

      </v-data-table>

      <div class="hidden-sm-and-up">
        <v-row align="center" class="ml-4 mr-4"
               v-if="formDetailMode !== 'NEW_DETIL' "
        >
          <v-col cols="12" md="6" sm="6">
            <v-autocomplete
                v-model="itemModified.fwarehouseBean"
                :items="itemsFWarehouse"
                item-value="id"
                item-text="description"
                :rules="rulesNotEmtpy"
                auto-select-first
                dense
                chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Gudang"
                persistent-hint
                :disabled="formDetailMode==='NEW_DETIL' || formDetailMode==='EDIT_DETIL' || itemModified.deliveryNumber != '' "
                @change="reloadExpeditionDestinationFtSalesdItemsDetail"
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="itemModified.fwarehouseBean=undefined"
                >
                  <v-avatar left>
                    <v-img :src="lookupImageUrlLazy(data.item)" class="v-size--x-small"></v-img>
                  </v-avatar>
                  {{ data.item.description }}
                </v-chip>
              </template>

              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <v-img :src="lookupImageUrlLazy(data.item)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.description}}</v-list-item-title>
                    <v-list-item-subtitle class="font-weight-light">
                      {{data.item.city1}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

          </v-col>

          <v-spacer></v-spacer>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="formDetailMode==='NEW_DETIL' || formDetailMode==='EDIT_DETIL' ||
                itemModified.fwarehouseBean==0 || itemModified.deliveryNumber != '' ||
                itemModified.fsalesmanBean ==0 || itemModified.fstoreBean ==0 || itemModified.fcustomerBean==0 "
              class="mb-4"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <v-list>
            <v-list-item v-for="(item, i) in ftSalesdItemsFiltered" :key="i">
              <v-row>
                <v-col cols="3">
<!--                  :lazy-src="lookupImageMaterialUrl_VeryLow(item)"-->
                  <v-img
                      :src="lookupImageMaterialUrl_VeryLow(item)"
                      alt="avatar"
                      width="50px"
                      height="50px"
                      class="rounded-lg"
                  >
                  </v-img>
                </v-col>
                <v-col cols="8">
                  <div>
                    <span class="caption">{{ lookupFMaterialPname(item.fmaterialBean) }}</span>
                    <span class="ml-1 blue--text">*{{ lookupFMaterialPcode(item.fmaterialBean) }}</span>
                  </div>
                  <div class="small-line-height">
                    <span class="mr-1 font-weight-light">Price</span>
                    <span class="small-line-height font-weight-light">{{ formattedCurrencyValue('', item.sprice2) }}</span>
                    <span class="ml-3 teal--text font-weight-black">
                      <span>{{item.qty-item.qtyKembali}}</span>
                      <span class="ml-1 font-weight-regular">{{ lookupUom4(item) }}</span>
                    </span>
                  </div>
                  <div class="small-line-height">
                    <span class="font-weight-light caption">IDR</span> <span class="font-weight-bold">{{ formattedCurrencyValue('', lookupTotalAfterDiscAfterPpn(item)) }}</span>
                  </div>
                  <v-divider class="mt-1" v-if="i<ftSalesdItemsFiltered.length-1"></v-divider>
                </v-col>
                <v-col cols="1">
                  <div>
                    <v-btn
                        @click="showDialogEdit(item)"
                        icon
                        x-small
                        :disabled="formDetailMode==='NEW_DETIL' || formDetailMode==='EDIT_DETIL' ||
                      itemModified.fwarehouseBean===0  || itemModified.deliveryNumber != '' "
                    >
                      <v-icon
                          small
                          color="warning"
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="mt-1">
                    <v-btn
                        @click="deleteDialogShow(item)"
                        icon
                        x-small
                        :disabled="formDetailMode==='NEW_DETIL' || formDetailMode==='EDIT_DETIL' || itemModified.deliveryNumber != '' "
                    >
                      <v-icon
                          small
                          color="red accent-4"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>

              </v-row>
            </v-list-item>
        </v-list>
      </div>


      <v-divider></v-divider>
      <v-flex class="d-flex justify-end mt-2">
        <v-spacer></v-spacer>
        <v-flex class="d-flex ml-4  mr-10  mt-1 mb-0" align-self-end>
            <v-text-field
                v-if="lookupFCustomerGroupByFCustomer(itemModified.fcustomerBean).lockDiscNota===false"
                v-model="itemModified.disc1RpAfterPpn"
                @blur="calcDisc1Plus(itemModified.disc1RpAfterPpn)"
                dense
                label="Potongan Disc"
                :hint="`${formattedCurrencyValue('', this.itemModified.disc1)} %`"
                :persistent-hint="true"
                type="number"
                :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
            ></v-text-field>
            <v-text-field
                v-if="lookupFCustomerGroupByFCustomer(itemModified.fcustomerBean).lockDiscNota===false"
                v-model="itemModified.disc2RpAfterPpn"
                @blur="calcDisc2Plus(itemModified.disc2RpAfterPpn)"
                dense
                label="Pot Biaya"
                :hint="`${formattedCurrencyValue('', this.itemModified.disc2)} %`"
                :persistent-hint="true"
                class="ml-2"
                type="number"
                :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
            ></v-text-field>

        </v-flex>

        <v-flex  class="d-flex mt-0 mb-0" align-self-end>
            <v-flex align-self-baseline>

              <div class="blue--text caption">
                <span class="font-weight-bold">
                  {{ formattedCurrencyValue('', itemModified.amountRpAfterPpn ) }}
                </span>
<!--                <span>-->
<!--                  {{ formattedCurrencyValue('', itemModified.disc1RpAfterPpn ) }}-->
<!--                </span>-->
<!--                +-->
<!--                <span>-->
<!--                  {{ formattedCurrencyValue('', itemModified.disc2RpAfterPpn ) }}-->
<!--                </span>-->
                <span>
                  -{{ formattedCurrencyValue('', itemModified.disc1RpAfterPpn + itemModified.disc2RpAfterPpn ) }}
                </span>
              </div>

<!--              TOTAL <span class="ml-2 font-weight-bold">{{ formattedCurrencyValue('', itemModified.amountAfterDiscPlusRpAfterPpn_FG) }}</span>-->
              TOTAL <span class="ml-2 font-weight-bold blue--text">{{ formattedCurrencyValue('', itemModified.amountRpAfterPpn - itemModified.disc1RpAfterPpn - itemModified.disc2RpAfterPpn - itemModified.discPlusRpAfterPpn_FG) }}</span>


            </v-flex>
        </v-flex>

      </v-flex>

    </v-card-text>

    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>

  </v-card>
</template>

<script>

import FileService from "../../../services/apiservices/file-service";
import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";

export default {
  components: {
    DeleteConfirmDialog
  },
  props:{
    // formDetailMode: String,
    itemModified: Object,

  },
  data(){
    return{
      title: '',

      multiSelect: false,
      itemSelectedIndex:-1,

      search: '',
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'avatar',
          width: '20%'
        },

        { text: 'Barang', value: 'fmaterialBean', width: "35%", sortable: false},
        { text: 'Qty+Disc', value: 'qty', width: "20%", sortable: false},
        { text: 'Total (+Ppn)', value: 'actions', width: "30%", align:'start', sortable: false }
      ],
      headersMobile: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'avatar',
          width: '200%'
        },
        // { text: 'Barang', value: 'fmaterialBean', align: 'start'}
        // { text: 'act', value: 'actions', width: "30%", align:'start', sortable: false }
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  watch: {
    /**
     * Akan diserahkan ke Back-End
     * Dan ini hanya menjadi perhitungan PENOLONG SEMENTARA
     */

    // ftSalesdItemsFiltered: {
    //   handler: function () {
    //     let total = 0
    //       this.ftSalesdItemsFiltered.forEach( item => {
    //         total += (item.qty* item.sprice2)
    //       })
    //     this.itemModified.amountAfterDiscPlusRpAfterPpn_FG = total
    //   }
    // },


  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },
    formDetailMode: {
      get () {
        return this.$store.state.sales.formDetailMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormDetailMode', value)
      }
    },


    ftSalesdItemsFiltered(){
      // return this.itemsFtSalesdItems
      // console.log(`>> ${JSON.stringify(this.itemModified.listFtSalesdItems)}`)
      return this.itemModified.listFtSalesdItems
    },
    itemsFDivision(){
      return this.$store.state.data.listFDivision.filter( x=> x.statusActive ===true)
    },
    itemsFMaterial(){
      return this.$store.state.data.listFMaterial.filter( x=> x.statusActive ===true)
    },
    itemsFWarehouse(){
      return this.$store.state.data.listFWarehouse.filter( x=> x.statusActive ===true)
    },
    itemsFTax(){
      return this.$store.state.sales.itemsFTax
    },
    itemsFCustomer(){
      return this.$store.state.data.listFCustomer
    },
    itemsFCustomerGroup(){
      return this.$store.state.data.listFCustomerGroup
    }

  },
  methods: {

    calcDisc1Plus(value){
        if (this.itemModified.deliveryNumber === '') {
          console.log(value)
          // if (value > 200000){
          //   this.itemModified.disc1RpAfterPpn = 200000
          // }

          let disc1 = this.itemModified.disc1RpAfterPpn / this.itemModified.amountRpAfterPpn * 100
          if (disc1 >60){
            disc1 = 60
            this.itemModified.disc1RpAfterPpn = this.itemModified.amountRpAfterPpn * (60/100)
          }

          this.itemModified.disc1 = disc1

          this.reloadExpeditionDestinationFtSalesdItemsDetail()
        }
    },
    calcDisc2Plus(value){
      /**
       * SEMENTARA DISINI MAINNY
       */
        if (this.itemModified.deliveryNumber === ''){
          console.log(value)
          // if (value > 200000){
          //   this.itemModified.disc2RpAfterPpn = 200000
          // }

          let disc2 = this.itemModified.disc2RpAfterPpn/this.itemModified.amountRpAfterPpn*100

          if (disc2 >30){
            disc2 = 30
            this.itemModified.disc2RpAfterPpn = this.itemModified.amountRpAfterPpn * (30/100)
          }

          this.itemModified.disc2= disc2

          this.reloadExpeditionDestinationFtSalesdItemsDetail()
        }
    },

    reloadExpeditionDestinationFtSalesdItemsDetail(){
        if (this.itemModified.fexpedisiBean > 0 && this.itemModified.fsubAreaBean > 0 &&
            this.lookupFCustomerGroupByFCustomer(this.itemModified.fcustomerBean).autoOngkir === true) {
          this.$emit('reloadExpeditionDestinationFtSalesdItemsDetail', "OKE")
        }
    },

    showDialogNew() {
      this.itemSelectedIndex =-1
      this.$emit('formDetailModeNew')
    },

    showDialogEdit (item) {
      const itemDetilModified = Object.assign({}, item)
      this.$emit('formDetailModeEdit', itemDetilModified)
    },

    deleteDialogShow (item) {
      this.itemSelectedIndex = this.ftSalesdItemsFiltered.findIndex(x=> x.id === item.id)
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, this.lookupFMaterialPname(item.fmaterialBean))
    },
    deleteItemConfirmedSingleSelect(){
      if (this.itemSelectedIndex > -1){
        this.$emit('formDetailDeleteItem', this.ftSalesdItemsFiltered[this.itemSelectedIndex])
        this.$refs.refDeleteConfirmDialog.setDialogState(false)
        this.itemSelectedIndex = -1

        // this.reloadExpeditionDestinationFtSalesdItemsDetail()
      }
    },

    deleteItemConfirmedMultiSelect(items){
      items.toString()
    },

    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

    lookupTotalAfterDiscAfterPpn(item) {

      const taxPecahan = 1 + (item.taxPercent/100)

      const totalRp = (item.sprice2 * taxPecahan)  * (item.qty-item.qtyKembali)
      const discRp = (totalRp * (item.disc1+item.disc2+item.disc3)) /100
      return totalRp -discRp
    },

    lookupUom4(item) {
      const fmaterialBean = this.itemsFMaterial.filter(x => x.id===item.fmaterialBean)
      if (fmaterialBean[0].uom4 !== undefined){
        return `${fmaterialBean[0].uom4}`
      }else {
        return ''
      }
    },

    lookupFMaterial(fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },
    lookupFMaterialPname(fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return `${str[0].pname}`
      }else {
        return ''
      }
    },
    lookupFMaterialPcode(fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return `${str[0].pcode}`
      }else {
        return ''
      }
    },
    lookupFTax(ftaxBean) {
      const str = this.itemsFTax.filter(x => x.id===ftaxBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },
    lookupFCustomerGroupByFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        const fcustomerGroup = this.lookupFCustomerGroup(str[0].fcustomerGroupBean)
        return fcustomerGroup
      }else {
        return ''
      }
    },
    lookupFCustomerGroup (fcustomerGroupBean) {
      const str = this.itemsFCustomerGroup.filter(x => x.id==fcustomerGroupBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupImageMaterialUrl_Low(item){
      const fmaterialBean = this.itemsFMaterial.filter(x => x.id===item.fmaterialBean)

      if (fmaterialBean[0].avatarImage===undefined || fmaterialBean[0].avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(fmaterialBean[0].avatarImage)
      }
    },
    lookupImageMaterialUrl_VeryLow(item){
      const fmaterialBean = this.itemsFMaterial.filter(x => x.id===item.fmaterialBean)

      if (fmaterialBean[0].avatarImage===undefined || fmaterialBean[0].avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(fmaterialBean[0].avatarImage)
      }
    },


  }

}
</script>

<style scoped>
  .small-line-height{
    line-height: 0.8em;
  }
</style>
