<template>
    <v-card
        elevation="1"
        class="ml-1 mt-2 mb-2 color-gradient-3"
    >
      <v-card-text>
<!--        <v-row no-gutters>-->
<!--          <v-col-->
<!--              cols="12"-->
<!--              sm="6"-->
<!--              md="6"-->
<!--          >-->

<!--            Total Bayar: Rp.-->
<!--            <span class="ml-1 font-weight-bold blue&#45;&#45;text">-->
<!--              <span>{{ formattedCurrencyValueRounded(totalBayar) }}</span>-->
<!--            </span>-->
<!--          </v-col>-->
<!--          <v-col-->
<!--              cols="12"-->
<!--              sm="6"-->
<!--              md="6"-->
<!--          >-->
<!--            <v-switch-->
<!--                v-model="itemModified.noBiaya"-->
<!--                :label="! itemModified.noBiaya?'No Biaya':'Dengan Biaya'"-->
<!--                x-small-->
<!--                dense-->
<!--            ></v-switch>-->
<!--          </v-col>-->
<!--        </v-row>-->

        <v-flex class="d-flex align-start">
          <v-flex class="d-flex align-baseline">
            Bayar
            <span class="ml-1 font-weight-black subtitle-1">
              <span>{{ formattedCurrencyValueRounded(totalBayar) }}</span>
            </span>
          </v-flex>
          <v-switch
              v-model="itemModified.noBiaya"
              :label="itemModified.noBiaya?'Gratis Admin':'Normal Biaya'"
              dense
              light
              class="ml-12 caption"
              hide-details
              inset
              :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
              @change="reloadExpeditionDestinationFtSaleshCheckOut"
          ></v-switch>
        </v-flex>

        <v-row>
          <v-col cols="12" sm="12" md="12" >
            <v-autocomplete
                v-model="itemModified.fpayMethodBean"
                :items="itemsFPayMethod"
                item-value="id"
                item-text="description"
                auto-select-first
                dense
                chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Metode Pembayaran"
                persistent-hint
                :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
                :append-icon="itemModified.fpayMethodBean==undefined?'mdi-emoticon-sad': ''"
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="itemModified.fpayMethodBean=undefined"
                >
                  <v-avatar left>
                    <v-img :src="lookupImageUrlLazy(data.item)"></v-img>
                  </v-avatar>
                  {{ data.item.description }}
                </v-chip>
              </template>

              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <v-img :src="lookupImageUrlLazy(data.item)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.description}}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-list-item-title class="font-weight-light">{{data.item.kode1}}</v-list-item-title>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

          </v-col>
        </v-row>

        <v-flex class="d-flex align-center" >
          <v-flex>
            <span class="caption ">
              #{{itemModified.orderno}}
              <span class="mt-1 blue--text" v-if="itemModified.invoiceno">#{{itemModified.invoiceno}}</span>
            </span>
          </v-flex>

          <v-flex v-if="lookupFCustomerGroupByFCustomer(itemModified.fcustomerBean).autoOngkir==false">
            <v-text-field
                v-model="itemModified.deliveryNumber"
                label="Resi MP (hapus dahulu untuk edit)"
                prefix="#"
                dense
                hide-details
            ></v-text-field>
          </v-flex>
          <v-flex v-else>
            <v-flex class="d-flex justify-end">AWB <span class="blue--text subtitle-2 font-weight-bold ml-1">#{{ itemModified.deliveryNumber }}</span></v-flex>
          </v-flex>
        </v-flex>

      </v-card-text>

      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

    </v-card>
</template>

<script>
import {format, parseISO} from "date-fns";
import FtSalesh from "../../../models/ft-salesh";
import FileService from "../../../services/apiservices/file-service";

export default {
  components:{
  },
  props:{
    itemModified: new FtSalesh(),
  },
  data() {
    return{

      snackBarMesage: '',
      snackbar: false,

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,


      selectedItemIndex: -1,
      itemDefault: '',
      selectFDivision: {id: 0, kode1: '', description: '' },

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  computed:{

    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },
    formDetailMode: {
      get () {
        return this.$store.state.sales.formDetailMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormDetailMode', value)
      }
    },
    // computedWithBiaya(){
    //   return ! this.itemModified.noBiaya
    // },
    itemsFDivision(){
      return this.$store.state.data.listFDivision
    },
    itemsFPayMethod(){
      return this.$store.state.data.listFPayMethod
    },

    totalBayar(){
      let bayar = 0
      if (this.itemModified.codRp >0){
        bayar = this.itemModified.codRp
      }else {
        bayar = parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp) + parseFloat(this.itemModified.biayaRp)
      }
      return bayar
    },

    itemsFCustomer(){
      return this.$store.state.data.listFCustomer
    },
    itemsFCustomerGroup(){
      return this.$store.state.data.listFCustomerGroup
    }

  },
  methods:{
    reloadExpeditionDestinationFtSaleshCheckOut(){
      if (this.itemModified.fexpedisiBean >0 && this.itemModified.fsubAreaBean >0 &&
          this.lookupFCustomerGroupByFCustomer(this.itemModified.fcustomerBean).autoOngkir==true){
          this.$emit('reloadExpeditionDestinationFtSaleshCheckOut', "OKE")
      }

    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },

    lookupFCustomerGroupByFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        const fcustomerGroup = this.lookupFCustomerGroup(str[0].fcustomerGroupBean)
        return fcustomerGroup
      }else {
        return ''
      }
    },
    lookupFCustomerGroup (fcustomerGroupBean) {
      const str = this.itemsFCustomerGroup.filter(x => x.id==fcustomerGroupBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    formattedCurrencyValueRounded(value){
      if(! value){ return "0"}
      return Math.round(parseFloat(value)).toLocaleString()
    },

  }

}

</script>

<style scoped>
.size .lSize {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
  font-size: 13px;
  cursor: pointer;
  user-select: none;
}
</style>
