<template>
  <div>
    <v-dialog
        v-model="dialogShow"
        :max-width="formDialogOptions.width"
        :style="{ zIndex: formDialogOptions.zIndex }"
        @keydown.esc.prevent="closeForm"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :hide-overlay="$vuetify.breakpoint.smAndDown"
    >

      <v-card>
          <v-toolbar
              dark
              color="brown"
              class="color-gradient-1"
              dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text class="mt-2">
            <v-row>
              <v-col v-for="(item, i) in itemsFExpedisi" :key="i"
                     cols="6" sm="4" md="3" xs="6" class="d-flex child-flex">
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-card
                            :elevation="hover? 6:1 "
                            class="rounded-lg no-gutters"
                            max-width="500"
                        >
                          <v-card-text class="no-gutters">
                            <div class="font-weight-black caption">{{item.description}}</div>
                            <v-flex class="d-flex">
                              <div>
                                <v-avatar>
                                  <v-img
                                      :src="lookupImageUrlLazy(item)"
                                      alt="avatar"
                                      width="50px"
                                      height="50px"
                                      class="rounded-lg"
                                  >
                                  </v-img>
                                </v-avatar>
                              </div>
                              <v-spacer></v-spacer>
                              <div class="mt-4 d-flex align-end">
                                <span class="font-weight-light caption">IDR.</span><div class="font-weight-bold">999.999,-</div>
                              </div>
                            </v-flex>

                          </v-card-text>
                          <v-card-actions class="grey lighten-4">
                            <span class="caption">
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn x-small depressed><span class="blue--text">Pilih</span><v-icon small>mdi-play</v-icon></v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>

                    </v-hover>
              </v-col>

            </v-row>

          </v-card-text>


      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import FileService from "@/services/apiservices/file-service";

export default {
  name: "FtsaleshShipToDialog",
  props:{
    itemsFExpedisi: []
  },
  data() {
    return {
      title: 'ShipToDialog',
      valid: true,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

    }
  },
  methods: {
    showDialog(){
      this.dialogShow =  true
    },
    closeForm(){
        this.dialogShow = false
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      return FileService.image_url_verylow(item.avatarImage)
    },

  }
}
</script>

<style scoped>

</style>