export default class FCustomer {
  constructor(
      id,
      custno,
      avatarImage,
      statusActive,
      branch,
      childOf,
      outletActive,
      fdivisionBean,
      custname,
      npwp,
      tunaikredit,
      lamaCredit,
      creditlimit,
      maxInvoice,
      address1,
      address2,
      district1,
      city1,
      state1,
      phone,
      countryCode,
      zipCode,
      email,
      whatsApp,
      latitude,
      longitude,
      fcustomerGroupBean,
      fsubAreaBean,
      fdistributionChannelBean,
      ftPriceAlthBean,
      noPromotionRules,
      custOfSalesman,

      mappingInCode1,
      mappingInCode2,
      mappingInCode3,

      mappingOutCode1,
      mappingOutCode2,
      mappingOutCode3,

      sourceId,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.custno = custno;
    this.avatarImage = avatarImage;
    this.statusActive = statusActive;
    this.branch = branch;
    this.childOf = childOf;
    this.outletActive = outletActive;
    this.fdivisionBean = fdivisionBean;
    this.custname = custname;
    this.npwp = npwp;
    this.tunaikredit = tunaikredit;
    this.lamaCredit = lamaCredit;
    this.creditlimit = creditlimit;
    this.maxInvoice = maxInvoice;
    this.address1 = address1;
    this.address2 = address2;
    this.district1 = district1;
    this.city1 = city1;
    this.state1 = state1;
    this.phone = phone;
    this.countryCode = countryCode;
    this.zipCode = zipCode;
    this.email = email;
    this.whatsApp = whatsApp;
    this.latitude = latitude;
    this.longitude = longitude;
    this.fcustomerGroupBean = fcustomerGroupBean;
    this.fsubAreaBean = fsubAreaBean;
    this.fdistributionChannelBean = fdistributionChannelBean;
    this.ftPriceAlthBean = ftPriceAlthBean;
    this.noPromotionRules = noPromotionRules;
    this.custOfSalesman = custOfSalesman;

    this.mappingInCode1 = mappingInCode1;
    this.mappingInCode2 = mappingInCode2;
    this.mappingInCode3 = mappingInCode3;

    this.mappingOutCode1 = mappingOutCode1;
    this.mappingOutCode2 = mappingOutCode2;
    this.mappingOutCode3 = mappingOutCode3;

    this.sourceId = sourceId;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}
